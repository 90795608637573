import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import galleryStyles from './gallery.module.css'
import GalleryFocus from '../components/gallery-focus'

const query = graphql`
  {
    allMarkdownRemark(filter: { fields: { collection: { eq: "gallery" } } }) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

class GalleryPage extends Component {
  constructor() {
    super()
    this.state = { activeImageNode: null }
  }

  onKeydown = e => {
    switch (e.key) {
      case 'Escape':
        this.onExitGalleryFocus()
        break
      case 'ArrowRight':
        this.changeToImageInOrder(1, this.edges)
        break
      case 'ArrowLeft':
        this.changeToImageInOrder(-1, this.edges)
        break
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown)
  }

  selectImage = imageNode => {
    this.setState(() => ({
      activeImageNode: imageNode,
    }))
  }

  changeToImageInOrder(increment, edges) {
    this.setState(state => {
      const currentIndex = edges.findIndex(
        ({ node }) => node === state.activeImageNode
      )
      return {
        activeImageNode:
          (edges[currentIndex + increment] || {}).node || state.activeImageNode,
      }
    })
  }

  onExitGalleryFocus = () => {
    this.setState(() => ({ activeImageNode: null }))
  }

  render = () => (
    <StaticQuery
      query={query}
      render={data => {
        const images = data.allMarkdownRemark.edges.map((edge, index) => (
          <div
            key={index}
            tabIndex={0}
            onKeyDown={e => e.key == 'Enter' && this.selectImage(edge.node)}
            onClick={() => this.selectImage(edge.node)}
            className={galleryStyles.imageContainer}
          >
            <Img
              className={galleryStyles.image}
              fluid={edge.node.frontmatter.image.childImageSharp.fluid}
              alt={''}
            />
          </div>
        ))

        this.edges = data.allMarkdownRemark.edges

        return (
          <Layout>
            <h1 style={{ paddingLeft: '20px' }}>Gallery</h1>
            <GalleryFocus
              imageNode={this.state.activeImageNode}
              incrementImageNode={increment =>
                this.changeToImageInOrder(
                  increment,
                  data.allMarkdownRemark.edges
                )
              }
              onExit={this.onExitGalleryFocus}
            />
            <div className={galleryStyles.gallery}>{images}</div>
          </Layout>
        )
      }}
    />
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object,
}

export default GalleryPage
