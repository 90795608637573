import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import galleryStyles from './gallery-focus.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome-setup'

class ServiceInfo extends React.Component {
  constructor(props) {
    super(props)

    this.nextButtonRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    // Focus next button when opening gallery focus mode
    if (!prevProps.imageNode && this.props.imageNode) {
      this.nextButtonRef.current.focus()
    }
  }

  render = () => {
    if (!this.props.imageNode) {
      return <div />
    }

    const arrowButton = increment => (
      <button
        className={`${galleryStyles.button} ${
          increment > 0 ? galleryStyles.buttonRight : galleryStyles.buttonLeft
        }`}
        ref={increment > 0 && this.nextButtonRef}
        onClick={() => this.props.incrementImageNode(increment)}
        title="Previous Image"
      >
        <FontAwesomeIcon
          className={galleryStyles.buttonIcon}
          icon={increment > 0 ? 'caret-right' : 'caret-left'}
          size={'6x'}
        />
      </button>
    )

    return (
      <div className={galleryStyles.container}>
        <button
          onClick={() => this.props.onExit()}
          className={galleryStyles.exit}
          title="Exit Image Focus"
        >
          <FontAwesomeIcon
            className={galleryStyles.exitIcon}
            icon={'times'}
            size={'2x'}
          />
        </button>
        {arrowButton(-1)}
        <Img
          fluid={this.props.imageNode.frontmatter.image.childImageSharp.fluid}
          className={galleryStyles.image}
        />
        {arrowButton(1)}
      </div>
    )
  }
}

ServiceInfo.propTypes = {
  imageNode: PropTypes.object,
  incrementImageNode: PropTypes.func,
  onExit: PropTypes.func,
}

export default ServiceInfo
