import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHammer,
  faArrowAltCircleDown,
  faCloudShowersHeavy,
  faWater,
  faRoad,
  faBox,
  faCaretRight,
  faCaretLeft,
  faTimes,
  faHome,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHammer,
  faArrowAltCircleDown,
  faCloudShowersHeavy,
  faWater,
  faRoad,
  faBox,
  faCaretRight,
  faCaretLeft,
  faTimes,
  faHome
)
